<template>
  <div class="col-12 mt-4">
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="1"
          label-size="sm">
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="search"
              @keydown="handleKeydown"
              type="search"
              placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Type</th>
            <th>Filepath</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>{{ item.id }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.filepath }}</td>
            <td>
              <b-button v-b-tooltip.hover title="Edit provider configuration" size="sm" variant="light"
                class="client-entry-action mr-2" :to="'/sftp/providers/' + item.id">
                <feather type="edit-2"></feather>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import * as R from 'ramda';
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'SFTPProvidersList',
  data() {
    return {
      search: '',
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    await this.fetchProviders();
  },
  created() {
    this.searchEvent = debounce(this.searchEvent, 350, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    handleKeydown() {
      this.searchEvent();
    },
    searchEvent() {
      if (this.search.length === 0) {
        this.fetchProviders();
        return;
      }

      if (!this.search || this.search.length < 3) {
        return;
      }

      this.fetchProviders(this.search);
    },
    async fetchProviders(search = '') {
      this.loading = true;
      let res;
      try {
        if (search !== '') {
          res = await this.$store.dispatch('Sftp/Provider/searchProviders', { folder: search });
        } else {
          res = await this.$store.dispatch('Sftp/Provider/getProvidersList');
        }
        this.items = res.data;
      } catch (err) {
        Vue.prototype.$noty.error(R.pathOr(err.message, [ 'response', 'data', 'message' ], err));
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style scoped>

.client-entry-action {
  min-width: initial;
  background-color: transparent;
  border: none;
}

</style>
